import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../../components/PageWrapper";
import styled from "styled-components";
import SEO from "../../../components/seo";
import BackButton from "../../../components/Core/BackButton";
import PageTitle from "../../../components/Core/PageTitle";
import { StaticImage } from "gatsby-plugin-image"

const Content = styled.div`

    $ a:hover {
        color: silver;
    }

    & h2 {
        margin: 1.5rem 0;
    }
`

const BlogArticle = ({data}) => {
    const headerConfig = {
        paddingBottom: 10,
    };

    return (
        <>  
            <PageWrapper headerConfig={headerConfig}>
                <SEO title="Anthos Family Office" />
                <Content>
                    <BackButton />
                    <Container className="">
                        <Row className="">
                            <Col className="col-lg-2"></Col>
                            <Col className="col-lg-6">
                                <PageTitle
                                    title="Anthos Family Office"
                                    align="left"
                                />
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="col-lg-2 text-right">
                                <div className="mb-4 border-top pt-7">
                                    {/* COFRA Group */}
                                </div>
                                <div>
                                    {/* Last updated<br />
                                    2 March, 2022 */}
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <StaticImage
                                    src="img_anthos_family_statues.jpg"
                                    alt="Statues of Tüötten, travelling traders in Germany"
                                    placeholder="blurred"
                                    layout="fixed"
                                    width={480}
                                    style={{maxWidth: '100%'}}
                                    className="mb-10"
                                />
                                <p>
                                    During the 1920s and 1930s, as C&A and the family expanded, specialists were brought in to look after the interests of the family. One of the entrepreneurs in the third generation initiated a plan to invest capital outside C&A - mainly in equity and real estate. The first entity that handled the asset management aspects of this 'investing together' was called Surrey. Initially Surrey was based in Antwerp and moved to Amsterdam in 1939. Investing together now lies at the core of the investment philosophy of Anthos Private Wealth Management.
                                </p>
                                <p>
                                    New activities and services were added over time, such as tax, estate planning, family legal and more.
                                </p>
                                <p>
                                    Today, Anthos has offices in seven countries (the Netherlands, Germany, Switzerland, Belgium, United Kingdom, USA/Canada). We serve our clients through about 100 dedicated professionals across these seven locations. Anthos provides integrated, tailored and trustworthy advice, based on a deep, shared understanding of our clients' personal situation and free of commercial interest.
                                </p>
                                <p>
                                    In short, our goal is to offer family members the best advice and services in our Core areas, with access to the best of the outside world (Connect), and with extra support when needed (Care Support).
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Content>
            </PageWrapper>
        </>
    )
}

export default BlogArticle;